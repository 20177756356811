<template>
  <div class="bank-integral-pack-style">
    <div class="packGood">
      <img src="@/assets/img/xy-card-bg.png" />
      <ul class="packul">
        <li>
          <span>订单号</span>
          <div>{{ resGood.ordNo }}</div>
        </li>
        <li>
          <span>订单时间</span>
          <div>{{ resGood.ordTime }}</div>
        </li>
        <li>
          <span>领取时间</span>
          <div>
            {{ resGood.excTime }}
          </div>
        </li>
      </ul>
    </div>
    <!-- 刮刮卡 openTag 是否刮开过 0-否 1-是 -->
    <scratch-card
      elementId="scratch"
      :moveRadius="15"
      :ratio="0.3"
      :clearCallback="clearCallbackMeds"
      coverColor="#222222"
      :coverImg="require('@/assets/img/bg_coating.png')"
      result=""
      v-if="resGood && [0].includes(resGood.openTag)"
    >
      <div class="surprise" slot="result">{{ resultMsg }}</div>
      <!-- <couponContent :couponData="resGood" slot="result" /> -->
    </scratch-card>

    <div
      v-if="resGood && [1].includes(resGood.openTag)"
      style="margin: 3.86vw 0 5.3vw;"
    >
      <couponContent :couponData="resGood" />
    </div>
    <div class="packGood" v-show="resGood.gcText" v-html="resGood.gcText"></div>
  </div>
</template>

<script>
import { reqXyGetCoupon, reqXyOpenNotify } from '@/api/bank-integral-optimize'
import md5 from 'md5'
import scratchCard from 'lzy-scratch-card'
import moment from 'moment'
import couponContent from '@/components/couponContent'
export default {
  name: 'BankCouponPackage',
  components: { scratchCard, couponContent },
  data() {
    return {
      redeemNo: this.$route.params.redeemNo,
      resGood: {},
      loading: false,
      resultMsg: '惊喜马上到'
    }
  },
  created() {
    this.getGoodMeds()
  },
  methods: {
    async getGoodMeds() {
      const loading = this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        message: '加载中...'
      })
      try {
        const redeemNo = this.redeemNo
        const seckey = redeemNo + process.env.VUE_APP_BASE_KEY
        const { data } = await reqXyGetCoupon({
          redeemCodeNo: redeemNo,
          md5String: md5(seckey)
        })
        data.ordTime = moment(data.ordTime).format('YYYY-MM-DD HH:mm:ss')
        data.excTime = moment(data.excTime).format('YYYY-MM-DD HH:mm:ss')
        this.resGood = data
        loading.clear()
      } catch (error) {
        console.log(error)
      }
    },
    async clearCallbackMeds() {
      try {
        await reqXyOpenNotify({ redeemCodeNo: this.redeemNo })
        this.getGoodMeds()
      } catch (error) {
        this.resultMsg = error?.data?.message
      }
    }
  }
}
</script>
<style lang="less" scoped>
.bank-integral-pack-style {
  background: #f7f7f7;
  padding: 3.067vw 4vw;
  width: 100vw;
  height: 100vh;
  .packGood {
    width: 92vw;
    margin: auto;
    background: #fff;
    padding: 2.13vw 2.66vw 3.73vw;
    & > img {
      width: 86vw;
      display: block;
      margin: auto;
    }
    .packul {
      & > li {
        display: flex;
        padding: 3vw 2.4vw 3.2vw;
        color: #8e8e93;
        font-size: 3.5vw;
        & > div {
          flex: 1;
          text-align: right;
          color: #636363;
        }
      }
    }
  }
  #scratch {
    width: 100% !important;
    height: 67px !important;
    margin: 3.86vw 0 5.3vw;
  }
  .surprise {
    line-height: 67px;
    background: #fff;
    font-size: 25px;
    color: #d2200d;
    font-weight: 700;
    text-align: center;
  }
}
</style>
