<template>
  <!-- 券码形式  gcShape 0:卡号卡密; 1:卡密; 2:卡号短链; 3:卡号短链验证码 -->
  <div class="content">
    <div v-show="[0].includes(couponData.gcShape)" style="padding: 9px 0">
      <div class="itemFlex" style="padding-bottom:15px">
        <label> 卡号：{{ couponData.cardNo }}</label>
        <div class="copyimg" @click="copyText(couponData.cardNo)">复制</div>
      </div>
      <div class="itemFlex">
        <label> 卡密：{{ couponData.cardPwd }} </label>
        <div class="copyimg" @click="copyText(couponData.cardPwd)">复制</div>
      </div>
    </div>

    <div class="itemFlex itemPad" v-show="[1].includes(couponData.gcShape)">
      <label>券码：{{ couponData.cardPwd }}</label>
      <div class="copyimg" @click="copyText(couponData.cardPwd)">复制</div>
    </div>

    <div class="itemFlex itemPad" v-show="[2].includes(couponData.gcShape)">
      <label>
        券码：
        <a :href="couponData.cardPwd" class="jump">{{ couponData.cardPwd }}</a>
      </label>
      <div class="copyimg" @click="copyText(couponData.cardPwd)">复制</div>
    </div>

    <div v-show="[3].includes(couponData.gcShape)" style="padding: 9px 0">
      <div class="itemFlex" style="padding-bottom:15px">
        <label>
          券码：<a :href="couponData.shortUrl" class="jump">{{
            couponData.shortUrl
          }}</a>
        </label>
        <div class="copyimg" @click="copyText(couponData.shortUrl)">复制</div>
      </div>
      <div class="itemFlex">
        <label> 验证码：{{ couponData.verifyCode }} </label>
        <div class="copyimg" @click="copyText(couponData.verifyCode)">复制</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    couponData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    copyText(val) {
      this.$copyText(val).then(() => {
        this.$toast.success('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #fff;
  padding: 0px 15px;
  .itemPad {
    padding: 20px 0px;
  }
}
.itemFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 3.5vw;
  color: #8e8e93;
  label {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
  .copyimg {
    margin-left: 2.66666666666666666666vh;
    color: #29b2fe;
    background: url(./../assets/img/xy-coupon-copy.png) left center no-repeat;
    background-size: contain;
    padding-left: 6vw;
  }
  .used {
    padding: 5px 10px;
    background: #1989fa;
    color: #fff;
    border-radius: 5px;
  }
  .jump {
    color: #1989fa;
  }
}
</style>
